document.addEventListener('DOMContentLoaded', () => {
  const bookingMenuItemEl = document.querySelector('.booking-menu-item a')
  if (bookingMenuItemEl) {
    bookingMenuItemEl.setAttribute('data-bs-toggle', 'modal')
  }

  const bookingModalEl = document.getElementById('modal-booking')
  if (bookingModalEl) {
    bookingModalEl.addEventListener('show.bs.modal', () => {
      const iframeEl = bookingModalEl.getElementsByTagName('iframe')[0]
      if (iframeEl && !iframeEl.hasAttribute('src') && iframeEl.hasAttribute('data-src')) {
        iframeEl.src = iframeEl.getAttribute('data-src')
      }
    })
  }
})
